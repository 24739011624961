export const packageData =[
  {
    id:1, 
    heading:"Experience the magical evening out - An Adventure that you'll never forget.",
    description1:"Leaving behind the bustle of the city in the afternoon, our Safari Guide will transport you by ( 4x4 ) Four Wheel drive for a thrilling journey over dunes through the Red Desert Of Dubai, deep in the heart of the Desert, from the crest of high dune. watch the sunset its orange glow over the vast expanse of rippling sands all around you as it sets.",
    description2:"The Desert Safari is a must for those who wish to experience the 'Real Desert'. Professional safari drivers take us on a thrilling roller-coaster ride across the golden dunes. We stop by the highest sand dune for a photo-shoot of the spectacular sunset. We then proceed to the Bedouin campsite located in the heart of the desert. A warm Arabian welcome awaits us, accompanied by refreshing Arabic coffee ('Kawa') and dates. Soft drinks, tea and water are also available. A brief camel ride adds fun. Ladies particularly enjoy adorning their arms with exquisite patterns of 'Henna' (leaf) tattoo. Smoking the 'Sheesha' or 'Hubbly Bubbly' is a typical Arabian experience not to be missed. Various mild and fruity flavors offer a tingling sensation.",
    description3:"While we relax on the carpet and cushions. A delicious BBQ is prepared under the star-lit sky. Later, an enchanting belly dancer appears, tearing apart the darkness of the night. She dances gracefully and rhythmically, keeping pace with the increasing tempo of authentic Arabian music. Excitement rents the air as the audience try to match her steps. Tanura Dance performances will liven up the evening. Delicious BBQ buffet dinner is served right on time, unveiling a feast of Arabian and international delicacies.",
  },
  {
    id:2, 
    heading:"Dune bashing in Dubai Desert is pure thrill.",
    description1:"You can enjoy 45 minutes of dune bashing in Lahbab desert that will accelerate your heart beat. A promising fun trip for a lifetime!!! Camel riding and sand boarding on Dubai’s dunes that you can never forget. It is the most favourite part of the trip for kids epically kids.",
    description2:"To serve the entertainment for all especially for women we offer henna painting and sheesha smoking. Wide variety of international dishes in dinner buffet with unlimited soft drinks, tea and coffee. Traditionally dressed up and traditionally belly & Tanura dance will be the best part of fun in desert camp.",
    description3:"Our professional staff will pick you from your hotel or residence on 4x4 vehicles and take you to the meeting point where you can enjoy Quad biking. After 20 minutes Quad biking we will move towards desert for Dune bashing that will be more thrilling. Opportunity to take pictures of sun set in desert. After the Dune bashing we will take you to the camp in desert where you can enjoy camel riding, unlimited soft drinks, dinner buffet for vegetarian and non vegetarian, henna painting, photography opportunity in Arabian dress, traditional belly dance and Tanura dance in desert camp.After that we will drop you back to your hotel or residence.",
  },
  {
    id:3, 
    heading:"Dune bashing in Dubai Desert is pure thrill.",
    description1:"You can enjoy 45 minutes of dune bashing in Lahbab desert that will accelerate your heart beat. A promising fun trip for a lifetime!!! Camel raiding and sand boarding on Dubai’s dunes that you can never forget. It is the most favourite part of the trip for kids epically kids.",
    description2:"To serve the entertainment for all especially for women we offer henna painting and sheesha smoking. Wide variety of international dishes in dinner buffet with unlimited soft drinks, tea and coffee. Traditionally dressed up and traditionally belly & Tanura dance will be the best part of fun in desert camp.",
    description3:"Our professional staff will pick you from your hotel or residence on 4x4 vehicles and take you to the meeting point where you can enjoy Quad biking. After 20 minutes Quad biking we will move towards desert for Dune bashing that will be more thrilling. Opportunity to take pictures of sun set in desert. After the Dune bashing we will take you to the camp in desert where you can enjoy camel riding, unlimited soft drinks, dinner buffet for vegetarian and non vegetarian, henna painting, photography opportunity in Arabian dress, traditional belly dance and Tanura dance in desert camp.After that we will drop you back to your hotel or residence",
  },
  {
    id:4, 
    heading:"The Desert Safari is a must for those who wish to experience the 'Real Desert'.",
    description1:"Professional safari drivers take us on a thrilling roller-coaster ride across the golden dunes. We stop by the highest sand dune for a photo-shoot of the spectacular sunset. We then proceed to the Bedouin campsite located in the heart of the desert.",
    description2:" A warm Arabian welcome awaits us, accompanied by refreshing Arabic coffee ('Kawa') and dates. Soft drinks, tea and water are also available. A brief camel ride adds fun.Smoking the 'Sheesha' is a typical Arabian experience not to be missed. Various mild and fruity flavors offer a tingling sensation. Ladies particularly enjoy adorning their arms with exquisite patterns of 'Henna' (leaf) tattoo.",
    description3:"While we relax on the carpet and cushions. A delicious BBQ is prepared under the star-lit sky. Later, an enchanting belly dancer appears, tearing apart the darkness of the night. She dances gracefully and rhythmically, keeping pace with the increasing tempo of authentic Arabian music. Excitement rents the air as the audience try to match her steps. Tanura Dance performances will liven up the evening. Delicious BBQ buffet dinner is served right on time, unveiling a feast of Arabian and international delicacies.",
  },
  {
    id:5, 
    heading:"Provide private car for Dune bashing in Dubai Desert is pure thrill.",
    description1:"You can enjoy 45 minutes of dune bashing in Lahbab desert that will accelerate your heart beat. A promising fun trip for a lifetime!!!",
    description2:"Camel raiding and sand boarding on Dubai’s dunes that you can never forget. It is the most favourite part of the trip for kids epically kids. To serve the entertainment for all especially for women we offer henna painting and sheesha smoking.",
    description3:" Wide variety of international dishes in dinner buffet with unlimited soft drinks, tea and coffee. Traditionally dressed up and traditionally belly & Tanura dance will be the best part of fun in desert camp.",
  },
  {
    id:6, 
    heading:"Provide private car for Dune bashing in Dubai Desert is pure thrill. ",
    description1:"You can enjoy 45 minutes of dune bashing in Lahbab desert that will accelerate your heart beat. A promising fun trip for a lifetime!!!",
    description2:"Camel raiding and sand boarding on Dubai’s dunes that you can never forget. It is the most favourite part of the trip for kids epically kids. To serve the entertainment for all especially for women we offer henna painting and sheesha smoking.",
    description3:"Wide variety of international dishes in dinner buffet with unlimited soft drinks, tea and coffee. Traditionally dressed up and traditionally belly & Tanura dance will be the best part of fun in desert camp.",
  },
]