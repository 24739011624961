import logo1 from '../image/essentialLogos/Logo1.svg';
import logo2 from '../image/essentialLogos/Logo2.svg';
import logo3 from '../image/essentialLogos/Logo3.svg';
import logo4 from '../image/essentialLogos/Logo4.svg';
import logo5 from '../image/essentialLogos/Logo5.svg';
import logo6 from '../image/essentialLogos/Logo6.svg';
import logo7 from '../image/essentialLogos/Logo7.svg';
import logo8 from '../image/essentialLogos/Logo8.svg';
import logo9 from '../image/essentialLogos/Logo8.svg';

export const essentialLogoData = {
  logo1: logo1,
  logo2: logo2,
  logo3: logo3,
  logo4: logo4,
  logo5: logo5,
  logo6: logo6,
  logo7: logo7,
  logo8: logo8,
  logo9: logo9,
};
