import React from 'react';
import Main from '../components/Main';
import Trip from '../components/Trip'

function Home() {
  return (
    <>
      <Main />
      <div id='trip'>
        <Trip />
      </div>
      
    </>
  )
}

export default Home