import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { logisticData } from '../data/LogisticData';
import DetailsBg from '../image/DetailsBg.png';

export default function TripLogistics() {
  const { eventId } = useParams();
  const [event, setEvent] = useState(null);

  useEffect(() => {
    const foundEvent = logisticData.find(eventItem => eventItem.id === parseInt(eventId));
    setEvent(foundEvent);
  }, [eventId]);

  return (
    <div className='mt-[50px] mx-0 sm:mx-[18px] md:mx-[30px] lg:mx-[120px]'>
      <div className='flex justify-center'>
        <section className='absolute bg-customYellow w-[184px] lg:w-[455px] h-[57px] lg:h-[110px] rounded-full mb-4 z-10'>
          <a href="tel:+971501220355" className='flex flex-col justify-center py-[13px] px-[27px] text-center text-customBrown'>
            <p className='font-ubuntu font-normal text-xs lg:text-xl leading-[13.79px] lg:leading-[22.98px]'>CALL FOR BOOKING</p>
            <p className='font-ubuntu font-bold text-base lg:text-[40px] leading-[18.38px] lg:leading-[45.96px]'>+971 50 122 0355</p>
          </a>
        </section>
        <section className='w-full mt-[25px] sm:mt-base md:mt-2xl lg:mt-[50px] py-[50px] lg:py-[100px]  bg-customBrown '
          style={{
            backgroundImage: `url(${DetailsBg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          {event && (
            <>
            <div className='w-full justify-center grid grid-rows-6 md:grid-rows-3 lg:grid-rows-3 grid-flow-col gap-x-2 gap-y-4 md:gap-x-4 lg:gap-x-4 xl:gap-8'>
              <div className='w-auto  text-white'>
                <p className='font-ubuntu font-normal text-xl leading-[50px] mx-4'>Rate</p>
                <div className='w-full flex items-center h-[58px] px-3  rounded-[100px] bg-royalbrown'>
                  <p className='font-ubuntu font-medium sm-custom:text-xl sm:text-xl lg:text-[25px] leading-[50px]'>{event.rate}</p>
                </div>
              </div>

              <div className='w-auto lg:w-[248px] text-white'>
                <p className='font-ubuntu font-normal text-xl leading-[50px] mx-4'>Tour Duration</p>
                <div className=' w-full flex items-center h-[58px] px-3   rounded-[100px] bg-royalbrown'>
                  <p className='font-ubuntu font-medium sm-custom:text-xl sm:text-xl lg:text-[25px] leading-[50px]'>{event.tourDuration}</p>
                </div>
              </div>
              <div className='w-auto lg:w-[248px] text-white'>
                <p className='font-ubuntu font-normal text-xl leading-[50px] mx-4'>Pick up & Drop Back</p>
                <div className='w-full flex items-center h-[58px] px-3  rounded-[100px] bg-royalbrown'>
                  <p className='font-ubuntu font-medium sm-custom:text-xl sm:text-xl lg:text-[25px] leading-[50px]'>{event.pickUpAndDropBack}</p>
                </div>
              </div>

              <div className='w-auto text-white'>
                <p className='font-ubuntu font-normal text-xl leading-[50px] mx-4'>Tour Service</p>
                <div className='flex items-center  h-[58px] px-3  rounded-[100px] bg-royalbrown'>
                  <p className='font-ubuntu font-medium sm-custom:text-xl sm:text-xl lg:text-[25px] leading-[50px]'>{event.tourService}</p>
                </div>
              </div>
              <div className='w-auto lg:w-[284px] text-white'>
                <p className='font-ubuntu font-normal text-xl leading-[50px] mx-4'>Pick Up</p>
                <div className='flex items-center  h-[58px] px-3 rounded-[100px] bg-royalbrown'>
                  <p className='font-ubuntu font-medium sm-custom:text-xl sm:text-xl lg:text-[25px] leading-[50px]'>{event.pickUp}</p>
                </div>
              </div>

              <div className='w-auto lg:w-[248px] text-white'>
                <p className='font-ubuntu font-normal text-xl leading-[50px] mx-4'>Language</p>
                <div className='flex items-center  h-[58px] px-3  rounded-[100px] bg-royalbrown'>
                  <p className='font-ubuntu font-medium sm-custom:text-xl sm:text-xl lg:text-[25px] leading-[50px]'>{event.language}</p>
                </div>
              </div>

            </div>
             
            </>
          )}
        </section>
      </div>
    </div>
  );
}



