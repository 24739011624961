import React, { useState } from 'react';
import { backgroundData } from '../data/BackgroundData'; 

const Trip = () => {

  const [itemsToShow, setItemsToShow] = useState(6);

  const showMoreItems = () => {
   setItemsToShow(itemsToShow + 3); 
  };

  return (
    <div className='mt-[62px] mx-[18px] sm:mx-[50px] md:mx-[90px] lg:mx-[130px]'>
      <h1 className='text-black font-ubuntu font-bold text-xs sm:text-sm md:text-lg lg:text-xl leading-[13.79px] sm:leading-4 md:leading-[19px] lg:leading-[22.98px]'>
        Most Popular Packages
      </h1>

      <div className='grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8  mt-8'>
      {backgroundData.slice(0, itemsToShow).map((item) => (
        <a href={`/details/${item.id}`} key={item.id} className='relative flex justify-end group '>
          <div className='absolute z-10 rounded-lg  px-[10px]  mr-4 mt-4 bg-royalYellow shadow-3xl w-[104px] h-[64px]  text-customBrown '>
            <p className='font-ubuntu font-normal text-xs  leading-[26px]'>From AED</p>
            <p className=' font-ubuntu font-bold text-3xl  leading-[26px]'>{item.rate}</p>
          </div>
          <div className='absolute z-10 bottom-4 left-4 text-white transform  transition-transform duration-500  translate-y-0 group-hover:translate-y-[-25%]'>
            <h1 className='font-ubuntu font-bold text-xl mb-2 leading-7'>{item.heading1}</h1>
            <h2 className='font-ubuntu font-normal text-lg leading-7'>{item.heading2}</h2>
          </div>
          <img src={require(`../image/packageBackgrounds/${item.image}.png`)} alt='' className='w-full h-full object-cover transform transition-transform duration-500 group-hover:scale-110' />  
        </a>
      ))}
      </div>
      {itemsToShow < backgroundData.length && (
         <div className='flex flex-row-reverse mt-9 text-customBrown sm:items-center'>
           <p
             className='underline font-ubuntu font-normal text-xl leading-[22.98px] cursor-pointer'
             onClick={showMoreItems}
           >
             View More
           </p>
         </div>
       )}
    </div>
  )
}

export default Trip
