import React from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../image/home/Logo.svg';
import AboutLogo from '../image/home/About.svg';
import Whatsapp from '../image/home/Whatzapp.svg';

const Header = () => {
  const navigate = useNavigate();

  const navigateTrip = () => {
    
    if (window.location.pathname === '/details') {
      navigate('/');
      const middleSection = document.getElementById('trip')
      if (middleSection) {
        middleSection.scrollIntoView({behavior:'smooth'})
      }
    } else {
      navigate('/');
    }
  }
  return (
    <div className='w-full flex justify-between items-center mt-[8px] px-[18px] sm:px-[30px] md:px-[55px] lg:px-[130px] '>
      <a href='/' onClick={navigateTrip} >
        <div className='w-[100px] h-[46px] sm:w-[184px] sm:h-[89px] cursor-pointer'>
          <img src={Logo} alt='Ideal Arabian' className='w-full h-full object-cover'/>
        </div>
      </a>
      <ul className='flex justify-center  items-center gap-[10px] '>
        <li className='w-[70px] h-[28px] sm:w-[121px] sm:h-[48px] bg-customWhite rounded-lg flex items-center justify-center'>
          <a href='/about' className='cursor-pointer'>
            <div className='flex gap-[6px]'>
              <img src={AboutLogo} alt='about' className='w-full h-full object-cover'/>
              <p className='font-sans font-medium text-xs sm:text-base text-customBrown leading-[19.65px] '>About</p>
            </div>
          </a>
        </li>
        <li className='w-[28px] h-[28px] sm:w-[48px] sm:h-[48px] '>
          <a href='http://wa.me/971501220355' className='cursor-pointer'>
            <img src={Whatsapp} alt='Whatsapp' className='w-full h-full object-cover'/>
          </a>
        </li>
      </ul>  
    </div>
  )
}


export default Header