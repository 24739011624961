export const logisticData = [
  {
    id:1,
    rate:"Adult 250/ Child 200 / Infant Free",
    tourService:"Daily / Afternoon - Evening",
    tourDuration:"6 Hours (Approx)",
    pickUp:"03:00 pm to 03:30 pm",
    pickUpAndDropBack:"Included",
    language:"English / Arabic"
  },
  {
    id:2,
    rate:"Adult 400/ Child 300 / Infant Free",
    tourService:"Daily / Afternoon - Evening",
    tourDuration:"6 Hours (Approx)",
    pickUp:"03:00 pm to 03:30 pm",
    pickUpAndDropBack:"Included",
    language:"English / Arabic"
  },
  {
    id:3,
    rate:"Adult 2 Person 1300 AED",
    tourService:"Daily / Afternoon - Evening",
    tourDuration:"6 Hours (Approx)",
    pickUp:"03:00 pm to 03:30 pm",
    pickUpAndDropBack:"Included",
    language:"English / Arabic"
  },
  {
    id:4,
    rate:"1200 AED",
    tourService:"Daily / Afternoon - Evening",
    tourDuration:"6 Hours (Approx)",
    pickUp:"03:00 pm to 03:30 pm",
    pickUpAndDropBack:"Included",
    language:"English / Arabic"
  },
  {
    id:5,
    rate:"1500 AED",
    tourService:"Daily / Afternoon - Evening",
    tourDuration:"6 Hours (Approx)",
    pickUp:"03:00 pm to 03:30 pm",
    pickUpAndDropBack:"Included",
    language:"English / Arabic"
  },
  {
    id:6,
    rate:"Adult 2000 AED",
    tourService:"Daily / Afternoon - Evening",
    tourDuration:"6 Hours (Approx)",
    pickUp:"03:00 pm to 03:30 pm",
    pickUpAndDropBack:"Included",
    language:"English / Arabic"
  },
]