import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { essentialsData } from '../data/EssentialsData';
import { essentialLogoData } from '../data/EssentialLogoData';

const TripEssentials = () => {
  const [logos] = useState(essentialLogoData);
  const { eventId } = useParams();
  const [event, setEvent] = useState({});

  useEffect(() => {
    const foundEvent = essentialsData.find(eventItem => eventItem.id === parseInt(eventId));
    setEvent(foundEvent);
  }, [eventId]);

  const displayEssentialItem = (label, value, logo) => (
    <div className='h-[85px] bg-white flex  justify-center'>
      <div className='w-[46px] h-[46px] rounded-full flex justify-center items-center bg-royalGray'>
        <img src={logos[logo]} alt='' className='' />
      </div>
      <div className='ml-6 h-[85px] w-[250px]'>
        <p className='font-ubuntu font-bold text-base leading-7 text-black'>{label}</p>
        <p className='font-ubuntu font-normal text-base leading-6 text-customAsh'>{value}</p>
      </div>
    </div>
  );

  return (
    <div className='mx-[18px] sm:mx-[50px] md:mx-[90px] lg:mx-[130px]' >
      <div className='mt-[50px]  grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
        {displayEssentialItem('Languages', event.language, 'logo1')}
        {displayEssentialItem('Departures', event.departures, 'logo2')}
        {displayEssentialItem('Availability', event.availability, 'logo3')}
        {displayEssentialItem('Dress Code', event.dressCode, 'logo4')}
        {displayEssentialItem('Duration', event.duration, 'logo5')}
        {displayEssentialItem('Transport', event.transport, 'logo6')}
        {displayEssentialItem('Activity', event.activity, 'logo7')}
        {displayEssentialItem('Estimated departure time', event.estimatedDepartureTime, 'logo8')}
        {displayEssentialItem('Estimated return time', event.estimatedReturnTime, 'logo9')}
      </div>
    </div>
  );
}

export default TripEssentials;





