export const backgroundData =[
  { 
    id:1,
    image:"package1",
    name:"Evening Desert Safari Shared Vehicle ",
    heading1:"Evening Desert Safari",
    heading2:"Shared Vehicle",
    rate:250,
  },
  {
    id:2,
    image:"package2",
    name:"Evening Safari Shared Vehicle with Quad Bike",
    heading1:"Evening Safari",
    heading2:"Shared Vehicle with Quad Bike",
    rate:400,
  },
  {
    id:3,
    image:"package3",
    name:"Evening Safari Shared Vehicle + Dune Buggy",
    heading1:"Evening Safari",
    heading2:"Shared Vehicle with Dune Buggy",
    rate:1000,
  },
  {
    id:4,
    image:"package4",
    name:"Private Evening Desert Safari ",
    heading1:"Evening Desert Safari",
    heading2:"Private",
    rate:1200,
  },
  {
    id:5,
    image:"package5",
    name:"Private Red Dune Safari + Quad Bike",
    heading1:"Private Red Dune Safari",
    heading2:"+ Quad Bike",
    rate:1500,
  },
  {
    id:6,
    image:"package6",
    name:"Private Red Dune Safari + Dune Buggy",
    heading1:"Private Red Dune Safari",
    heading2:"+ Dune Buggy",
    rate:2000,
  }
]