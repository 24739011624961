import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { listData } from '../data/ListData';
import checkmark from '../image/checkmark.svg';

export default function TripLists() {

  const { eventId } = useParams();
  const [event, setEvent] = useState();

  useEffect(() => {
    if(eventId){
      const foundEvent = listData.find(eventItem => eventItem.id === parseInt(eventId));
      setEvent(foundEvent);
    }   
  }, [eventId]);

  const displayList = (title,list) => (
    <div>
        <h1 className='font-ubuntu font-bold text-xl leading-[22.98px] mt-10'>{title}:</h1>
        <ul className='font-ubuntu font-normal text-base lg:text-lg  mt-8'>
          {list.map((item, index) => (
            <li key={index} className='flex flex-start  mb-2'>
              <img src={checkmark} alt="Checkmark" className="w-4 lg:w-5 h-4 lg:h-5 mr-2 mt-1" />
              {item}
            </li>
          ))}
        </ul>
      </div>
  );

  return (
    <div className='mt-[50px] px-[18px] sm:px-[50px] md:px-[90px] lg:px-[130px] '>

      {event && (
        <>
          {displayList('TOUR INCLUSIONS',event.tourInclusions )}
          {displayList('AT THE CAMP',event.atTheCamp)}
          {displayList('FOOD',event.food)}
          {displayList('ENTERTAINMENT',event.entertainment)}
        </>
      )}

    </div>
  )
}