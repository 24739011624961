export const listData = [
  {
    id: 1,
    tourInclusions: [
      'Pick up from your Hotel - Location - Residence in Dubai & Sharjah',
      'Pick by: 4x4 SUV Land Cruisers for the Adventure Safari & Camp',
      'Drive Through Camel Farms',
      'Dune Bashing and drive to reach the high Dune (with photo stop)',
      '45 Minutes Dune Bashing By Safari License Professional Driver',
      'Dune Bashing at Lehbab Desert ( Known as Red Dunes)'
    ],
    atTheCamp: [
      'Traditional welcome at camp with Arabic Tea, Coffee',
      'Arabic Sweets & Fresh Fruits',
      'Sheesha Facility (Apple Flavor)',
      'Hubbly Bubbly’ Smoking facility',
      'FREE Arabic Dress Photography',
      'FREE Camel Riding',
      'FREE Sand Boarding',
      'FREE Henna Painting',
    ],
    food:[
      'Refreshment with Unlimited Water, Tea, Coffee & Soft Drinks',
      'International Buffet Dinner with Barbeque (Vegetarian & Non-vegetarian dishes)',
    ],
    entertainment: [
      'Full Light and Sound display on stage and around the Camp',
      'Fire Show',
      'Two Tanoura Show Entertainment',
      'Two Live Belly Dance Show on traditional Arabic Music',
      'Drop back to your Hotel - Residence in Dubai/Sharjah'
    ],
  },
  {
    id:2,
    tourInclusions: [
      'Pick up from your Hotel - Location - Residence in Dubai & Sharjah',
      'Pick by: 4x4 SUV Land Cruisers for the Adventure Safari & Camp',
      'Drive Through Camel Farms',
      'Dune Bashing and drive to reach the high Dune (with photo stop)',
      '30 Minutes Dune Bashing By Safari License Professional Driver',
      '20 Minutes Quad Bike Ride',
      'Dune Bashing at Lehbab Desert ( Known as Red Dunes)'
    ],
    atTheCamp: [
      'Traditional welcome at camp with Arabic Tea, Coffee',
      'Arabic Sweets &amp; Fresh Fruits',
      'Sheesha Facility (Apple Flavor)',
      'Hubbly Bubbly’ Smoking facility',
      'FREE Arabic Dress Photography',
      'FREE Camel Riding',
      'FREE Sand Boarding',
      'FREE Henna Painting',
    ],
    food:[
      'Refreshment with Unlimited Water, Tea, Coffee & Soft Drinks',
      'International Buffet Dinner with Barbeque (Vegetarian & Non-vegetarian dishes)',
    ],
    entertainment: [
      'Full Light and Sound display on stage and around the Camp',
      'Belly Dance Show on traditional Arabic music',
      'Captivating Tanura Dance Entertainment',
      'Drop back your Hotel - Location - Residence in Dubai & Sharjah',
    ],
  },
  {
    id:3,
    tourInclusions: [
      'Pick up from your Hotel - Location - Residence in Dubai & Sharjah',
      'Pick by: 4x4 SUV Land Cruisers for the Adventure Safari & Camp',
      'Drive Through Camel Farms',
      'Dune Bashing and drive to reach the high Dune (with photo stop)',
      '30 Minutes Dune Bashing By Safari License Professional Driver',
      '30 Minutes 1000 CC Polaris Dune Buggy Ride',
      'Dune Bashing at Lehbab Desert ( Known as Red Dunes)'
    ],
    atTheCamp: [
      'Traditional welcome at camp with Arabic Tea, Coffee',
      'Arabic Sweets & Fresh Fruits',
      'Sheesha Facility (Apple Flavor)',
      'Hubbly Bubbly’ Smoking facility',
      'FREE Arabic Dress Photography',
      'FREE Camel Riding',
      'FREE Sand Boarding',
      'FREE Henna Painting',
    ],
    food:[
      'Refreshment with Unlimited Water, Tea, Coffee & Soft Drinks',
      'International Buffet Dinner with Barbeque (Vegetarian & Non-vegetarian dishes)',
    ],
    entertainment: [
      'Full Light and Sound display on stage and around the Camp',
      'Belly Dance Show on traditional Arabic music',
      'Captivating Tanura Dance Entertainment',
      'Drop back your Hotel - Location - Residence in Dubai & Sharjah',
    ],
  },
  {
    id:4,
    tourInclusions: [
      'Pick up from your Hotel - Location - Residence in Dubai & Sharjah',
      'Pick by: 4x4 SUV Land Cruisers for the Adventure Safari & Camp',
      'Vehicle Capacity Maximum 6 person',
      'Drive Through Camel Farms',
      'Dune Bashing and drive to reach the high Dune (with photo stop)',
      '45 Minutes Dune Bashing By Safari License Professional Driver',
      'Dune Bashing at Lehbab Desert ( Known as Red Dunes)'
    ],
    atTheCamp: [
      'Traditional welcome at camp with Arabic Tea, Coffee',
      'Arabic Sweets & Fresh Fruits',
      'Sheesha Facility (Apple Flavor)',
      'Hubbly Bubbly’ Smoking facility',
      'FREE Arabic Dress Photography',
      'FREE Camel Riding',
      'FREE Sand Boarding',
      'FREE Henna Painting', 
    ],
    food:[
      'Refreshment with Unlimited Water, Tea, Coffee & Soft Drinks',
      'International Buffet Dinner with BBQ (Vegetarian & Non-vegetarian dishes)',
    ],
    entertainment: [
      'Full Light and Sound display on stage and around the Camp',
      'Belly Dance Show on traditional Arabic music',
      'Captivating Tanura Dance Entertainment',
      'Drop back your Hotel - Location - Residence in Dubai & Sharjah',
    ],
  },
  {
    id:5,
    tourInclusions: [
      'Pick up from your Hotel - Location - Residence in Dubai & Sharjah',
      'Pick by: 4x4 SUV Land Cruisers for the Adventure Safari & Camp',
      'Vehicle Capacity Maximum 6 person',
      'Drive Through Camel Farms',
      'Dune Bashing and drive to reach the high Dune (with photo stop)',
      '45 Minutes Dune Bashing By Safari License Professional Driver',
      '20 Minutes Quad Bike Ride 1 Double Seater Bike',
      'Dune Bashing at Lehbab Desert ( Known as Red Dunes)'
    ],
    atTheCamp: [
      'Traditional welcome at camp with Arabic Tea, Coffee',
      'Arabic Sweets & Fresh Fruits',
      'Sheesha Facility (Apple Flavor)',
      'Hubbly Bubbly’ Smoking facility',
      'FREE Arabic Dress Photography',
      'FREE Camel Riding',
      'FREE Sand Boarding',
      'FREE Henna Painting',
      
    ],
    food:[
      'Refreshment with Unlimited Water, Tea, Coffee & Soft Drinks',
      'International Buffet Dinner with BBQ (Vegetarian & Non-vegetarian dishes)',
    ],
    entertainment: [
      'Full Light and Sound display on stage and around the Camp',
      'Belly Dance Show on traditional Arabic music',
      'Captivating Tanura Dance Entertainment',
      'Drop back your Hotel - Location - Residence in Dubai & Sharjah',
    ],
  },
  {
    id:6,
    tourInclusions: [
      'Pick up from your Hotel - Location - Residence in Dubai & Sharjah',
      'Pick by: 4x4 SUV Land Cruisers for the Adventure Safari & Camp',
      'Vehicle Capacity 6 Person',
      'Drive Through Camel Farms',
      'Dune Bashing and drive to reach the high Dune (with photo stop)',
      '45 Minutes Dune Bashing By Safari License Professional Driver',
      '30 Minutes 1000 CC Polaris Dune Buggy Ride ( 1 Buggy 4 Seater)',
      'Dune Bashing at Lehbab Desert ( Known as Red Dunes)'
    ],
    atTheCamp: [
      'Traditional welcome at camp with Arabic Tea, Coffee',
      'Arabic Sweets & Fresh Fruits',
      'Sheesha Facility (Apple Flavor)',
      'Hubbly Bubbly’ Smoking facility',
      'FREE Arabic Dress Photography',
      'FREE Camel Riding',
      'FREE Sand Boarding',
      'FREE Henna Painting',
    ],
    food:[
      'Refreshment with Unlimited Water, Tea, Coffee & Soft Drinks',
      'International Buffet Dinner with BBQ (Vegetarian & Non-vegetarian dishes)',
    ],
    entertainment: [
      'Full Light and Sound display on stage and around the Camp',
      'Belly Dance Show on traditional Arabic music',
      'Captivating Tanura Dance Entertainment',
      'Drop back your Hotel - Location - Residence in Dubai & Sharjah',
    ],
  }
]