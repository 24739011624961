import React, { useState } from 'react';
import { packageData } from '../data/PackageData';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import TripEssentials from '../components/TripEssentials';
import TripLogistics from '../components/TripLogistics';
import TripBackground from '../components/TripBackground';
import TripImages from '../components/TripImages';
import TripLists from '../components/TripLists';


export default function Details() {
  const { eventId } = useParams();
  const [event, setEvent] = useState([]);

  useEffect(() => {
    if(eventId){
      const foundEvent = packageData.find(eventItem => eventItem.id === parseInt(eventId));
      setEvent(foundEvent);
    }
    
  }, [eventId]);

  return (
    <div>
      
      <TripBackground />
      
      <div className='mt-[50px] mx-[18px] sm:mx-[50px] md:mx-[90px] lg:mx-[130px]  text-black'>
        <h1 className='font-ubuntu font-bold lg:font-medium text-xl lg:text-2xl leading-[26px] lg:leading-6 mb-4'>
          {event.heading}
        </h1>
        <p className='font-ubuntu font-light lg:font-normal text-sm lg:text-base leading-5 lg:leading-6'>
          {event.description1}
        </p>
      </div>

      <TripEssentials />

      <div className='mt-[50px]  mx-[18px] sm:mx-[50px] md:mx-[90px] lg:mx-[130px]  text-black font-ubuntu font-light lg:font-normal text-sm lg:text-base leading-5 lg:leading-6'>
        <p className='mb-5'>{event.description2}</p>
        <p className='mb-5'>{event.description3}</p>
      </div>

      <TripImages />
      <TripLists />
      <TripLogistics /> 
      
      
    </div>
  )
}
