export const essentialsData = [
  {
    id:1,
    language:"English",
    departures:"Daily",
    availability:"Year round",
    dressCode:"Modest dress and comfortable walking shoes are recommended",
    duration:"Late afternoon until early evening",
    transport:"4-wheel drive Land Cruiser or similar (shared)",
    activity:"Moderate",
    estimatedDepartureTime:"3pm",
    estimatedReturnTime:"9pm",
  },
  {
    id:2,
    language:"English",
    departures:"Daily",
    availability:"Year round",
    dressCode:"Modest dress and comfortable walking shoes are recommended",
    duration:"Late afternoon until early evening",
    transport:"4-wheel drive Land Cruiser or similar (shared)",
    activity:"Moderate",
    estimatedDepartureTime:"3pm",
    estimatedReturnTime:"9pm",
  },
  {
    id:3,
    language:"English",
    departures:"Daily",
    availability:"Year round",
    dressCode:"Modest dress and comfortable walking shoes are recommended",
    duration:"Late afternoon until early evening",
    transport:"4-wheel drive Land Cruiser or similar (shared)",
    activity:"Moderate",
    estimatedDepartureTime:"3pm",
    estimatedReturnTime:"9pm",
  },
  {
    id:4,
    language:"English",
    departures:"Daily",
    availability:"Year round",
    dressCode:"Modest dress and comfortable walking shoes are recommended",
    duration:"Late afternoon until early evening",
    transport:"4-wheel drive Land Cruiser or similar (shared)",
    activity:"Moderate",
    estimatedDepartureTime:"3pm",
    estimatedReturnTime:"9pm",
  },
  {
    id:5,
    language:"English",
    departures:"Daily",
    availability:"Year round",
    dressCode:"Modest dress and comfortable walking shoes are recommended",
    duration:"Late afternoon until early evening",
    transport:"4-wheel drive Land Cruiser or similar (shared)",
    activity:"Moderate",
    estimatedDepartureTime:"3pm",
    estimatedReturnTime:"9pm",
  },
  {
    id:6,
    language:"English",
    departures:"Daily",
    availability:"Year round",
    dressCode:"Modest dress and comfortable walking shoes are recommended",
    duration:"Late afternoon until early evening",
    transport:"4-wheel drive Land Cruiser or similar (shared)",
    activity:"Moderate",
    estimatedDepartureTime:"3pm",
    estimatedReturnTime:"9pm",
  },
  
]