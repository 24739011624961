import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { tripImagesData } from '../data/TripImageData';


export default function TripImages() {

  const { eventId } = useParams();
  const [event, setEvent] = useState();
  const [itemsToShow, setItemsToShow] = useState(3);

  useEffect(() => {
    if(eventId){
      const foundEvent = tripImagesData.find(eventItem => eventItem.id === parseInt(eventId));
      setEvent(foundEvent);
    }   
  }, [eventId]); 

  const showMoreItems = () => {
    setItemsToShow(itemsToShow + 3); 
  };
  
  return (
    <div className=' mt-[90px] mx-[18px] sm:mx-[50px] md:mx-[90px] lg:mx-[130px]'>
  

      {event ? (
        <div >
          <div className='grid justify-items-center grid-col-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4'>
          {event.images.slice(0, itemsToShow).map((image, index) => (
            <img key={index} src={image} alt='Event Images' className='h-full w-full object-cover' />
          ))}
          </div>
          

          {itemsToShow < event.images.length && (
            <div className='flex flex-row-reverse mt-9 text-customBrown sm:items-center'>
              <p
                className='underline font-ubuntu font-normal text-2xl leading-[22.98px] cursor-pointer'
                onClick={showMoreItems}
              >
                Show More
              </p>
            </div>
          )} 
        </div>
      ) : (
        <p>No images found for this package trip</p>
      )}

      

    </div>
  )
}
