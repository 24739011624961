import React from 'react';

export default function Footer() {
  return (
    <>
    <div className='flex flex-col'>
      <footer className='bg-footer-texture bg-no-repeat bg-cover bg-center w-full h-[250px] mt-[10px]  py-4 flex justify-center items-center' >
        <span className='font-ubuntu font-medium text-sm leading-3 text-customGray'>&copy; 2023 Ideal Arabian</span>
      </footer>
    </div>
    </>
  )
}
