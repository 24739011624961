import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { backgroundData } from '../data/BackgroundData';
export default function TripBackground() {
  const { eventId } = useParams();
  const [event, setEvent] = useState(null);

  useEffect(() => {
    if (eventId) {
      const foundEvent = backgroundData.find(eventItem => eventItem.id === parseInt(eventId));
      setEvent(foundEvent);
    }
  }, [eventId]);

  return (
    <div className="mt-2 ">
      {event && (
        <div className=" px-0 sm:px-[30px] md:px-[55px] lg:px-[130px] relative w-full h-[186.96px] sm:h-[300px] md:h-[400px] lg:h-[418px]">
          <img
            src={require(`../image/packageBackgrounds/${event.image}.png`)}
            alt={`${event.heading1} - ${event.heading2}`}
            className="w-full h-full object-cover"
          />
          <div className='absolute top-[30px] sm:top-[70px] md:top-[80px] lg:top-[100px] py-5 ml-[18px] lg:ml-[30px] text-white'>
            <h1 className="font-ubuntu font-medium text-xl sm:text-[32px] md:text-[42px] lg:text-[52px] leading-7 sm:leading-[42px] md:leading-[56px] lg:leading-[72px]">{event.heading1}</h1>
            <h2 className="font-ubuntu font-medium text-xl sm:text-[32px] md:text-[42px] lg:text-[52px] leading-7 sm:leading-[42px] md:leading-[56px] lg:leading-[72px]">{event.heading2}</h2>
            <section className="absolute w-[164px] sm:w-[200px] md:hw-[240px] lg:w-[281px] h-[49px] sm:h-[58px] md:h-[67px] lg:h-[78px]  bg-customYellow rounded-full mt-1 ">            
              <a href="tel:+971 50 122 0355" className=' text-customBrown flex flex-col py-2 px-4'>
                <h1 className="font-ubuntu font-normal text-xs md:text-sm lg:text-base leading-[13.79px] sm:leading-4 lg:leading-[18.38px]]">CALL FOR BOOKING</h1>  
                <h2 className="font-ubuntu font-bold text-base sm:text-lg md:text-xl lg:text-[28px] leading-[18.38px] sm:leading-6 md:leading-7 lg:leading-[32.17px]">+971 50 122 0355</h2>            
              </a>
            </section>
          </div>
          
        </div>
      )}
    </div>
  );
}