import React from 'react';
import AboutBg from '../image/about/AboutBackground.png';
import SkyLine from '../image/about/SkyLine.svg';
import Desert from '../image/about/Desert.svg';
import BurjArab from '../image/about/BurjArab.svg';

const About = () => {
  return (
    <div className='mt-2'>
      <div className='px-0 sm:px-[30px] md:px-[55px] lg:px-[130px] relative  h-[200px] sm:h-[300px] md:h-[400px] lg:h-[418px]'>
        <div className='absolute flex flex-col text-white bottom-4  left-4 sm:left-[50px] md:left-[90px] lg:left-[190px]'>
          <h1 className='font-ubuntu font-medium text-[32px] sm:text-[42px] md:text-[72px] lg:text-[82px] leading-[10px] sm:leading-[32px] md:leading-[52px] lg:leading-[72px]'>This is Desert,</h1><br/>
          <h2 className='font-ubuntu font-medium text-[22px] sm:text-[32px] md:text-[42px] lg:text-[52px] leading-[10px] sm:leading-[32px] md:leading-[52px] lg:leading-[72px]'>About Desert Safari.</h2>
        </div>
        <img src={AboutBg} alt=' ' className='w-full h-full object-cover' />
      </div>

      <div className='mt-[50px] mx-[18px] sm:mx-[50px] md:mx-[90px] lg:mx-[130px] font-ubuntu font-normal  text-sm sm:text-base  leading-6 text-black'>
        <p className='mb-6'>
          One of the most popular things to do in Dubai, desert safaris offer a multitude of entertainment and adventurous activities that attract people across the world. They involve activities like 
          sand boarding, quad biking, camel riding, dune buggy, sheesha smoking, etc. 
        </p>
        <p className='mb-6'>
          Despite the hot weather, the desert safari is one of the best Dubai tourist attractions. With thousands of desert safari adventures available in Dubai, it is really hectic to find the one which
          will give you the best experience. Below is the list of few pre-safari aspects which will ensure a fun and memorable time.
        </p>
        <p className='mb-6'>
          If you are thinking of planning a Dubai trip and you want to go on a Desert Safari, then it is better to visit between November and March because the temperature is generally less. Otherwise, 
          during the summer season, the temperature rises up to 55-degree centigrade. Usually, the desert safari in Dubai starts from the afternoon and continues whole night after sunrise.
        </p>
        
      </div>
      <div className='grid justify-items-center grid-col-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-[90px] mx-[18px] sm:mx-[50px] md:mx-[90px] lg:mx-[130px]'>
        <img src={SkyLine} alt='' className='w-full h-full object-cover' />
        <img src={Desert} alt='' className='w-full h-full object-cover' />
        <img src={BurjArab} alt='' className='w-full h-full object-cover' />
      </div>

    </div>
    
  )
}

export default About