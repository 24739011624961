import React from 'react';
import BackgroundImage from '../image/home/HomeBg.png';
import PathImage from '../image/home/Path.svg';
import ScrollDown from '../image/home/ScrollDown.svg';

const Main = () => {
  return (  
    <div>
      <div className='w-full h-[186.96px] sm:h-[300px] md:h-[400px] lg:h-[647px] mt-2 px-0 sm:px-[10px] md:px-[20px] lg:px-[40px]' >
        <img src={BackgroundImage} alt='/' className='w-full h-full object-cover'/>
        <div className='m-auto'>
          <div className='absolute top-[90px] sm:top-[150px] md:top-[180px] lg:top-[238px]  flex flex-col text-white pl-[18px] lg:pl-[25px] xl:pl-[90px]'>
            <h1 className='font-ubuntu font-light text-[25px] sm:text-[40px] md:text-[60px] lg:text-[70px] xl:text-[80px] leading-[30.07px] sm:leading-[50px] md:leading-[60px] lg:leading-[96.24px]'>Welcome to</h1>
            <h2 className=' font-ubuntu font-bold text-[33px] sm:text-[60px] md:text-[70px] lg:text-[90px] xl:text-[115px] leading-[39.7px]  sm:leading-[65px] md:leading-[75px] lg:leading-[138.34px]'>Ideal Arabians</h2>
            <div className='w-[150px] sm:w-[250px] md:w-[250px] lg:w-[400px] xl:w-[450px]  ml-[-35px] lg:ml-[-100px]'>
              <img src={PathImage} alt='/' className=''/>
              <div className='absolute top-[74px] sm:top-[120px] md:top-[130px] lg:top-[250px] text-customBrown'>
                <div className=' flex flex-col ml-10 lg:ml-[100px] mt-[5px] md:mt-4 '>
                  <h1 className='font-ubuntu font-light text-[13px] sm:text-[23px] lg:text-[33px]  xl:text-[43px] leading-[14.3px] sm:leading-[23px] md:leading-[25px] lg:leading-[47.3px]'>Your Adventure</h1>
                  <h2 className='font-ubuntu font-bold text-[13px] sm:text-[23px]  lg:text-[33px] xl:text-[43px] leading-[14.3px] sm:leading-[23px] md:leading-[25px] lg:leading-[47.3px]'>Begins here...</h2></div>
                </div>
              </div>
            </div>
        </div>
      </div>

      <div className='mt-10 mx-[18px] sm:mx-[35px] md:mx-[52px] lg:mx-[70px] flex flex-col items-center justify-center'>
        <h1 className='font-ubuntu font-bold text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-[40px] leading-[22.98px] sm:leading-[30px] md:leading-[40px] lg:leading-[45.96px] text-customBlack mb-3'>Explore our Desert Safari</h1>
        <p className='font-ubuntu font-thin text-sm sm:text-base  lg:text-xl xl:text-2xl leading-5 sm:leading-6 md:leading-8 lg:leading-9 text-center text-black mb-6 '>One of the most popular things to do in Dubai, desert <br className='sm:hidden'/> safaris offer a multitude of <br className='hidden md:block'/> entertainment and <br className='sm:hidden'/> adventurous activities that attract people across the<br className='sm:hidden'/> world.</p>
        <div className="animate-bounce mt-4">
           <img src={ScrollDown} alt='scroll-down'  className='w-[14px] sm:w-[18px] md:w-[22px] lg:w-[27px]'/> 
        </div>
      </div>

    </div>

    
  )
}

export default Main

