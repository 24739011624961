import bellyDance from '../image/eventImages/BellyDance.png';
import tanoura1 from '../image/eventImages/TanouraDance.png';
import tanoura2 from '../image/eventImages/Tanoura2.JPG';
import tanoura3 from '../image/eventImages/Tanoura3.JPG';
import photo1 from '../image/eventImages/Photoshoot.png';
import photo2 from '../image/eventImages/PhotoShoot2.jpg';
import photo3 from '../image/eventImages/PhotoShoot3.jpg';
import photo4 from '../image/eventImages/Photoshoot4.jpg';
import photo5 from '../image/eventImages/Photoshoot5.jpg';
import photo6 from '../image/eventImages/Photoshoot6.jpg';
import desert1 from '../image/eventImages/Desert1.jpg';
import desert2 from '../image/eventImages/Desert2.jpg';
import falcon from '../image/eventImages/Falcon.jpg';
import falcon2 from '../image/eventImages/Falcon2.jpg';
import fireShow1 from '../image/eventImages/FireShow.jpg';
import fireShow2 from '../image/eventImages/FireShow2.jpg';
import fireShow3 from '../image/eventImages/FireShow3.jpg';
import sandBoard1 from '../image/eventImages/SandBoard1.jpg';
import private1 from '../image/eventImages/PRIVATE 1.jpg';
import duneBuggy1 from '../image/eventImages/DuneBuggy1.jpg'
import duneBuggy2 from '../image/eventImages/DuneBuggy2.jpeg';
import quadbike1 from '../image/eventImages/QuadBike1.jpg';
import quadbike2 from '../image/eventImages/QuadBike2.jpg';

export const tripImagesData = [
  {
    id: 1,
    images: [bellyDance,tanoura1,photo1],
  },
  {
    id: 2,
    images: [bellyDance,desert1,falcon,fireShow1,desert2,fireShow2,photo2,tanoura3,sandBoard1],
  },
  {
    id: 3,
    images: [bellyDance,desert1,falcon,fireShow1,duneBuggy2,tanoura3,sandBoard1,private1],
  },
  {
    id: 4,
    images: [photo6,photo4,bellyDance,fireShow3,sandBoard1,desert1,private1],
  },
  {
    id: 5,
    images: [bellyDance,desert1,fireShow1,photo3,quadbike2,quadbike1,photo5,tanoura2,falcon2],
  },
  {
    id: 6,
    images: [desert2,sandBoard1,duneBuggy2,duneBuggy1,tanoura2,fireShow1,bellyDance],
  },
]